import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// 承载页
import Hosting from '../views/Hosting/Hosting.vue'
import productcenter from '../views/product-center/product-center.vue'
import secret from '../views/secret/secret.vue' //  隐私协议
import secretPOP from '../views/secret/components/secretPOP.vue' //  隐私协议
// manage管理中心
import manage from '../views/manage/manage.vue'
import Incoming from '../views/manage/Incoming.vue'
import customer from '../views/manage/customer.vue' // 客户管理
import stockgoods from '../views/manage/stockgoods.vue' // 进件管理 
// 企业查询
import enterprise from '@/views/manage/enterprise.vue'

import workbenches from '../views/manage/workbenches.vue' // 工作台

// Aboutus 关于我们
import Aboutus from '../views/Aboutus/Aboutus.vue'
import producthub from '../views/producthub/producthub.vue'
// 移动端口
import mobilev from '@/views/mobilev/mobilev.vue'
// 管理中心分页面





const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  // 移动端
  {
    path: '/mobilev',
    name: 'mobilev',
    component: mobilev,
  },

  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: () => import('../views/Aboutdetail/aboutdetail.vue')
  },
  {
    path: '/Hosting',
    name: 'Hosting',
    component: Hosting,
  },
  {
    path: '/producthub',
    name: 'producthub',
    component: producthub,
    meta: {
      title: "产品中心"
    }
  },
  // 产品中心
  {
    path: '/productcenter',
    name: 'productcenter',
    component: productcenter,
    meta: {
      title: "产品中心"
    }
  },

  {
    path: '/secret',
    name: 'secret',
    component: secret,
    meta: {
      title: "隐私协议"
    }
  },

  {
    path: '/secretPOP',
    name: 'secretPOP',
    component: secretPOP,
    meta: {
      title: "隐私协议弹窗"
    }
  },


  // 管理中心
  {
    path: '/manage',
    name: 'manage',
    component: manage,
    meta: {
      title: "管理中心"
    }
  },
  // 进件管理
  {
    path: '/Incoming',
    name: 'Incoming',
    component: Incoming,
    redirect: "/workbenches",
    meta: {
      title: "进件管理"
    },
    children: [
      {
        path: '/workbenches',
        name: 'workbenches',
        component: workbenches,
        meta: {
          title: "工作台"
        }
      },
      {
        path: '/customer',
        name: 'customer',
        component: customer,
        meta: {
          title: "客户管理"
        }
      },
      {
        path: '/enterprise',
        name: 'enterprise',
        component: enterprise,
        meta: {
          title: "企业查询"
        }
      },
      {
        path: '/stockgoods',
        name: 'stockgoods',
        component: stockgoods,
        meta: {
          title: "进件管理"
        }
      },]
  },





  // 关于我们
  {
    path: '/Aboutus',
    name: 'Aboutus',
    component: Aboutus,
    meta: {
      title: "关于我们"
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
