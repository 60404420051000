import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
// 目的是：判断当前用户中本地存储是否有token，使用户在登录之前，只能访问到/login页面，其他的页面是访问不到的。
router.beforeEach((to, from, next) => {
  // to 即将进入的路由
  // from 在哪个路由进入的
  // next 放行
  let token1 = localStorage.getItem("tokenPC") || ""; //在本地存储中获取token
  if (token1) {
    next();
  } else {
    //在没有token的前提下，to下面的path是否为/login，如果不是则页面跳转到登录页面
    if (to.path == "/stockgoods") {
      next({ path: "/manage" }); //跳转页面到login页
    };
    if (to.path == "/workbenches") {
      next({ path: "/manage" }); //跳转页面到login页
    };
    if (to.path == "/customer") {
      next({ path: "/manage" }); //跳转页面到login页
    };
    if (to.path == "/enterprise") {
      next({ path: "/manage" }); //跳转页面到login页
    };
    next();
  }
});

import store from './store'
import 'lib-flexible'
// swiper样式组件
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'// swiper
import 'swiper/css';
import '@/public/global.scss' /*引入公共样式*/
// 组件库
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
// 接口代理
import serApi from "@/serApi/serApi";
// 应用共同的事件
import event1 from '@/public/eventBus';
//引入js文件
import { postFormAPI } from "./views/request/service.js"
import { getListAPI } from "./views/request/service.js"
import { deleteListAPI } from "./views/request/service.js"
const app = createApp(App);

app.config.globalProperties.$post = postFormAPI; // post方法
app.config.globalProperties.$get = getListAPI; // get方法
app.config.globalProperties.$det = deleteListAPI; // det方法
app.config.globalProperties.$serApi = serApi; // 数据请求封装文件
app.config.globalProperties.$untils = event1;

app.use(VueAwesomeSwiper).use(ElementPlus).use(store).use(router)
app.mount('#app')
// createApp(App).use(VueAwesomeSwiper).use(ElementPlus).use(store).use(router).mount('#app')
