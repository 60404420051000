
// 函数防抖
let timer: any = null;
const antishake = (event: Function, time: any = 1000) => {
  if (timer) {
    clearTimeout(timer); // 清除未执行的代码，重置回初始化状态
  }
  timer = setTimeout(() => {
    event();
    // console.log("函数防抖");
  }, time);
};


const public33 = (val: any) => {
  console.log("公共的钩子函数", val);
};
export default {
  public33,
  antishake
}
