<template>
  <div class="protocolBox">
    <div class="protitle">微伴客用户协议</div>
    <div class="protONE">
      欢迎您与【江苏微伴客科技有限公司】订立本注册协议（简称“本协议”），并使用“微伴客”（简称“本平台”）基于微信公众号提供的服务（简称“本服务”）实现您的需求。
      请您知晓，当您通过网络确认或以其他方式接受本协议约定，即表示您已充分阅读、理解并接受本协议的全部内容，并将按照本协议约定享受权利和履行相应的义务。除本协议正文的相关约定之外，您还需遵守与本协议附件（如有）、本服务相关的已经发布的各项规则、页面提示、操作流程、公告或通知。在接受本协议之前，特请您仔细阅读下文的全部内容
      <span class="BoldAccent">（尤其是以粗体下划线标注的内容）。</span>
    </div>
    <!-- 第一条接纳义务 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第一条 代理人确认及服务接纳</div>
      <div class="tit1-1"> 1.1 注册代理人需满足下列条件： </div>
      <div class="small1">（1）中华人民共和国大陆地区内居民（不含港、澳、台地区）；</div>
      <div class="small1">（2）具备通过本平台提供服务，履行相应义务的完全民事权利能力及行为能力。</div>
      <div class="tit1-1">1.2 代理人需接受本平台全部协议条款及本平台各项规则。</div>
      <div class="tit1-1">1.3代理人确认本协议全部条款并完成注册的，视为代理人符合注册条件， 能够独立承担因通过本平台提供服务所产生的权利义务。</div>
      <div class="tit1-1">1.4 本平台保留在中华人民共和国现行有效之法律、法规范围内接受、 拒绝、终止/中止代理人通过本平台提供服务之资格。</div>
    </div>
    <!-- 第二条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第二条 代理人注册信息</div>
      <div class="tit1-1">2.1 代理人首次通过本平台提交个人信息并确认本协议的，且通过本平台审核认证的， 即成为本平台注册代理人</div>
      <div class="tit1-1"> 2.2 代理人应自行如实向平台提供注册信息。代理人应当确保其提供的注册信息真实、准确、完整、合法有效。如代理人提供的
        注册信息不合法、不真实、不准确、不详尽，代理人需承担由此产生的相应责任及后果，本平台保留随时终止注册代理人资格的权利。</div>
      <div class="tit1-1">2.3 代理人应当谨慎地保存、使用其本平台账号、密码、手机验证码等信息。代理人不得将本平台账号向他人透露、借用，
        否则代理人应当承担由此产生的全部后果及责任。</div>
      <div class="tit1-1">2.4 我们充分尊重和重视代理人的个人信息保护</div>
    </div>
    <!-- 第三条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第三条 本平台服务</div>
      <div class="tit1-1">3.1 服务的内容,具体如下</div>
      <div class="tit1-1">代理人通过本平台，向具有贷款需求的企业客户宣传和推广我们合作机构的相关贷款产品。</div>
      <div class="small1">（1）本平台有权对其提供的服务或产品形态进行升级或其他调整，并将及时告知您。</div>
      <div class="small1">
        （2）您理解，与相关网络服务有关的设备（如个人电脑、手机、及其他与接入互联网或移动网络有关的装置）及所需的费用（如为接入互联网而支付的电话费及上网费，为使用而移动网而支付的手机费）均应由您自行负担。</div>
      <div class="tit1-1">3.2 服务的更新</div>
      <div class="small1">（1）为了改善代理人体验、完善服务内容，本平台将不断努力开发新的服务，并为您不时提供相关软件更
        新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）</div>
      <div class="small1">（2）为了保证本服务的安全性和功能的一致性，本平台有权不经向您特别通知而对相关软件进行更新， 或者对相关软件的部分功能效果进行改变或限制。</div>
      <div class="small1">（3）本平台新版本服务发布后，旧版本的相关软件可能无法使用。本平台不保证旧版本的相关软件继续 可用及提供相关的服务，请您随时核对并更新最新版本。</div>
    </div>
    <!-- 第四条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第四条 代理人承诺</div>
      <div class="tit1-1">4.1 代理人应当妥善保管本人的本平台账号、密码、绑定的手机号码、手机验证码等信息。对于非因
        本平台过错产生的上述信息泄露所导致的代理人损失，本平台不承担任何责任。</div>
      <div class="tit1-1">4.2代理人承诺在通过平台提供服务过程中应该诚实、守信地履行相关义务，否则将承担包含但不限于下 列后果：</div>
      <div class="small1">（1）代理人将因违约行为承担相应的违约责任；</div>
      <div class="small1">（2）代理人将因违约行为承担相应的诉讼后果。</div>
      <div class="tit1-1">4.3 代理人享有言论自由的权利。但代理人的言行不得违反《计算机信息网络国际联网安全保护管理办法》、
        《互联网信息服务管理》、《维护互联网安全的决定》、《互联网新闻信息服务管理规定》、《互联网跟帖评论服务管理规则》
        等相关法律规定，不得在本平台相关板块发布、传播或以其它方式传送含下列内容之一的信息：</div>
      <div class="small1">（1）反对宪法所确定的基本原则的；</div>
      <div class="small1">（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>
      <div class="small1">（3）损害国家荣誉和利益的；</div>
      <div class="small1">（4）煽动民族仇恨、民族歧视、破坏民族团结的；</div>
      <div class="small1">（5）破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
      <div class="small1">（6）散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
      <div class="small1">（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的。</div>
      <div class="small1">（8）侮辱或者诽谤他人，侵害他人合法权利的；</div>
      <div class="small1">（9）煽动非法集合、结社、游行、示威、聚众扰乱社会秩序的；</div>
      <div class="small1">（10）以非法民间组织名义活动的；</div>
      <div class="small1">（11）含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或者其它道德上令人反感的内容；</div>
      <div class="small1">（12）侵犯他人知识产权或其他合法权益的；</div>
      <div class="small1">（13）含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</div>
    </div>
    <!-- 第五条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第五条 关于合同</div>
      <div class="tit1-1">5.1本协议采用电子文本形式制成，并在本平台系统上保留存档。代理人通过本平台系统点击确认或以其他方式选择接受本协议，
        即表示已同意接受本协议的全部内容以及与本平台各项规则。</div>
      <div class="tit1-1"> 5.2 代理人应当妥善保管自己的账户、密码等账户信息，不得以账户信息被盗用或其他理由否认 已订立的协议的效力或不履行相关义务。</div>
    </div>
    <!-- 第六条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第六条 责任限制</div>
      <div class="tit1-1">除非另有明确的书面说明，本平台及其所包含的或以其它方式通过本平台提供给代理人的全部信息、内容、材料、
        产品（包括软件）和服务，均是在“按现状”和“按现有”的基础上提供的。如因不可抗力或其它平台无法控制的原因、本平台
        系统崩溃或无法正常使用等导致代理人无法向通过提供本平台提供服务的，本平台不承担任何责任。</div>
    </div>
    <!-- 第七条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第七条 联系信息更新</div>
      <div class="tit1-1">代理人通过本平台提供服务期间，代理人本人姓名、身份证号码、手机号码、银行账户等信息如发生变更，应当在相关信息发生
        变更之日起三日内将更新后的信息提供给本平台。因代理人未能及时提供上述变更信息而带来的损失或额外费用应由代理人自行承担。</div>
    </div>
    <!-- 第八条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第八条 适用法律及争议解决</div>
      <div class="tit1-1">8.1 本协议的签订、履行、终止、解释均适用中华人民共和国法律。</div>
      <div class="tit1-1">8.2 因履行本协议所产生的争议应当通过优化协商解决；如协商不成，则本协议任意一方均可 向北京仲裁委员会申请仲裁。</div>
    </div>
    <!-- 第九条协议 -->
    <div class="commonSTle">
      <div class="OverallArticle1">第九条 其他</div>
      <div class="tit1-1">9.1 本协议自代理人签署并注册成为本平台代理人之日起生效，除非本平台终止本协议或者代理人 丧失本平台代理人资格，否则本协议始终有效。</div>
      <div class="tit1-1">9.2 本协议终止并不免除代理人根据本协议或其他有关协议、规则、已经发送的行为或达成的交易 项下所应承担的义务或责任。</div>
      <div class="tit1-1">9.3 本平台未行使或执行本服务协议任何权利或规定，不构成对前述权利之放弃。</div>
      <div class="tit1-1">9.4 本协议未尽事项按照本平台现有及不时发布的各项规则执行。</div>
      <div class="tit1-1">9.5 如本协议中的任何一条或多条被确认为无效，该无效条款并不影响本协议其他条款的效力。</div>
    </div>
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="scss" scoped>
.protocolBox {
  // text-align: center;
  // outline: 1px solid crimson;

  .protitle {
    text-align: center;
    color: #333;
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .protONE {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    font-family: PingFang SC;
    color: #2e3033;
    margin-bottom: 16px;
    // font-family: "SF Pro Text", "Helvetica Neue", Helvetica, Tahoma, Arial, "Microsoft Yahei", 微软雅黑, "PingFang SC", 苹方, sans-serif;

  }

  .BoldAccent {
    text-decoration: underline;
  }

  .commonSTle {
    line-height: 30px;
    margin-bottom: 20px;
  }

  //  第一条 代理人确认及服务接纳
  .OverallArticle1 {
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
    color: #2e3033;
    font-family: PingFang SC;
    margin-bottom: 6px;
  }

  //  1.1 注册代理人需满足下列条件：
  .tit1-1 {
    font-size: 14px;
    color: #2e3033;
  }

  // （1）中华人民共和国大陆地区内居民（不含港、澳、台地区）；
  .small1 {
    padding-left: 18px;
    box-sizing: border-box;
  }
}
</style>