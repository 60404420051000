
// const Honline = "http://p.jswbk.com" // 韩总h5测试环境
const Honline = "http://www.jswbk.com" // 韩总h5 正式环境

// const proxy = "/api" // 测试环境(大根)
// const proxy = "http://p.jswbk.com" // 测试环境(大根)
const proxy = "http://www.jswbk.com" // 正式环境(大根)

// const LOCAL_URL_ONLINE = "http://p.jswbk.com" // 图片回显 测试环境
const LOCAL_URL_ONLINE = "http://www.jswbk.com" // 图片回显 正式环境


module.exports = {
  account: Honline + '/v1/service/a',
  imgUrl: LOCAL_URL_ONLINE + "/v1/pc/file/download?fileUrl=",//图片回写
  areaINFO: proxy + "/v1/pc/area_info/getProvinceList", // 获取省份
  urbanarea: proxy + "/v1/pc/area_info/findByProvinceId/", // 根据省code获取市
  productinfo: proxy + "/v1/pc/pi_product_info/list", // 获取产品列表
  smscode: proxy + "/v1/pc/sms/getSmsVerifyCode", // 发送手机验证码
  postlogin: proxy + "/v1/pc/login/phoneLogin", // 登录信息
  // 工作台相关接口
  poststatic: proxy + "/v1/pc/work_bench/statistic", // 客户数 进件数
  GETentryAnalyze: proxy + "/v1/pc/cu_entry_record/entryAnalyze", // 近七天数据分析
  // 客户管理 相关接口
  GETcustom: proxy + "/v1/pc/ci_customer_info/customerList", // 
  // 渠道用户详情
  GETdetail: proxy + "/v1/pc/cu_channel_user/detail",
  POSTprovince: proxy + "/v1/pc/area_info/getProvinceList",// 获取所有省份
  getInfo: proxy + "/v1/pc/ci_corporation_info/list",// 企业查询列表
  postLOGout: proxy + "/v1/pc/login/logout",// 退出登录
  postfinProId: proxy + "/v1/pc/area_info/findByProvinceId/", // 根据省id获取市
  getAreaAll: proxy + "/v1/pc/area_info/getAreaAll",// 获取所有的省市区
  IncomList: proxy + "/v1/pc/cu_entry_record/list", // 进件管理接口列表
  export: proxy + "/v1/pc/cu_entry_record/export",// 进件管理 - 导出
  Sysmessl: proxy + "/v1/pc/sys_message_receiver/list",// 消息列表
  redaSys: proxy + "/v1/pc/sys_message_receiver/read/",// 消息已读 

}