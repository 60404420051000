
import axios from "axios";
import mitt from '@/plugins/Bus';
import {
  defineComponent, toRefs, reactive, onMounted, onBeforeUnmount, onUnmounted,
} from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  setup() {
    const data1 = reactive({
      actitle: "1" as any,
      scroll: 0 as any,
      appshow: false,
      linkSHOW: true,
      Webjson: {
        foo: "a",
        f: "ncrestF",
        req_type: "rf",
        req_id: "sta_point_clog",
        inst_id: "wbk",
        inst_verify: "MmE2MWIzMTQyYWRhNDAzN2EyZjlhMDk5ZDk5NzIyYzY=",
        inst_no: "wbk",
        enc: "",
      },
      uupoint: "PC:首页" as any, //页面
      iip: "" as any, // 登陆者ip
      iid: "" as any, // 登陆者id
      type: "page" as any, // 页面还是弹窗
      websock: null as any, // 初始化websocket
      rec: null as any, // 定义的定时器
      wsUrl: <string>"ws://h.jswbk.com/v1/service/apimws", // 测试环境
      // wsUrl: <string>"ws://sdfghg.jswbk.com/v1/service/apimws", // 正式环境
      // 获取当前地址
      // urliP: <string>'http://test.jswbk.com:18001/ip',// 后端ip - 测试1
      // urliP: <string>'http://p.jswbk.com/ip',// 后端ip - 测试2
      // urliP: <string>"http://www.jswbk.com/ip",// 后端ip - 正式环境
      urliP: <string>'api/ip',// 后端ip - 代理

    });
    // 初始化钩子函数
    onMounted(() => {
      let alink = localStorage.getItem("tokenPC");
      if (alink) {
        data1.linkSHOW = false;
      } else {
        data1.linkSHOW = true;
      };
      window.addEventListener("scroll", scrollToTop);
      data1.actitle = sessionStorage.getItem("activeId") || "1";
      getIPAddress(); // 获取用户id
      data1.iid = localStorage.getItem("iid");
      const router = useRouter(); // 重新定义一个路由跳转的方法 使用路由跳转 router.push(/index)
      //根据不同的设备跳转不一样的页面
      if (_isMobile()) {
        // console.log("移动端口 ");
        router.push('/mobilev'); // 到移动端首页
        data1.appshow = false;
      } else {
        // console.log('pc端口');
        data1.appshow = true;
        switch (data1.actitle) {
          case "1":
            router.push('/');
            break;
          case '2':
            router.push('/producthub');
            break;
          case '3':
            router.push('/Hosting');
            break;
          case '4':
            router.push('/about');
            break;
          case '5':
            router.push('/Aboutus');
            break;
          default:
            break;
        }
      }
      initWebSocket(); // 初始化websocket程序
    });

    onUnmounted(() => {
      data1.websock.close();
    });

    mitt.on('aTob', () => {
      console.log("隐私htnl");
      data1.actitle = sessionStorage.getItem("activeId");
    });
    // 初始化websocket
    const initWebSocket = () => {
      switch (data1.actitle) {
        case "1":
          data1.uupoint = "PC:首页";
          break;
        case '2':
          data1.uupoint = "PC:产品中心";
          break;
        case '3':
          data1.uupoint = "PC:综合解决方案";
          break;
        case '4':
          data1.uupoint = "PC:资讯中心";
          break;
        case '5':
          data1.uupoint = "PC:关于我们";
          break;
        case '6':
          data1.uupoint = "PC:管理中心";
          break;
        default:
          break;
      }
      data1.Webjson.enc = `{\"uid\":"",\"ip\":"${data1.iid}\",\"point\":"${data1.uupoint}\",\"type\":"${data1.type}\"}`;
      // console.log("初始化websocket的数据", data1.Webjson);

      data1.websock = new WebSocket(data1.wsUrl); //初始化信息
      data1.websock.onmessage = websocketonmessage;
      data1.websock.onopen = websocketonopen;
      data1.websock.onerror = websocketonerror;
      data1.websock.onclose = websocketclose;
    }
    // 成功接收数据
    let websocketonmessage = (e: any) => {
      const redata = JSON.parse(e.data);
      // console.log(" WebSocket成功接收数据", redata);
    }
    //连接建立之后执行send方法发送数据
    let websocketonopen = () => {
      // console.log("让我看看时候链接成功 ???", data1.websock.readyState);
      if (data1.websock.readyState === 1) { websocketsend(); return }
    }

    //数据发送
    let websocketsend = () => {
      data1.websock.send(JSON.stringify(data1.Webjson));
    }
    //连接建立失败重连(失败重连)
    let websocketonerror = () => {
      data1.rec && clearTimeout(data1.rec);
      data1.websock.close();
      data1.rec = setTimeout(() => {
        // 延迟5秒重连  避免过多次过频繁请求重连
        initWebSocket();
      }, 5000);
    }
    // 关闭
    let websocketclose = (e: any) => {
      data1.websock.close();
      // console.log('webscoket关闭啦啦啦!!!', e);
      // initWebSocket();
    }

    // 判断设备
    const _isMobile = () => {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
    // 获取用户的ip地址
    const getIPAddress = () => {
      // const urliP: string = 'http://api.ipify.org?format=json';// 官方地址
      axios.get(data1.urliP).then((res: any) => {
        // console.log(" 用户地址ip ", res.data);
        if (res.data) {
          data1.iid = res.data;
          localStorage.setItem("iid", res.data)
        } else {
          data1.iid = "";
        }
      });

    };
    onBeforeUnmount(() => {
      window.removeEventListener("scroll", scrollToTop);
    });

    const scrollToTop = () => {
      data1.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (data1.scroll >= 80) { } else {
        // console.log(" 不想让你滚动啊  ");
      }
    };

    // 点击事件
    const handleClick = (a: number) => {
      let alink = localStorage.getItem("tokenPC");
      sessionStorage.setItem("sideNum", "1");
      if (alink) {
        data1.linkSHOW = false;
      } else {
        data1.linkSHOW = true;
      };
      data1.actitle = JSON.stringify(a);
      sessionStorage.setItem("activeId", JSON.stringify(a));
      scrollTo(0, 0);
      // data1.iid = localStorage.getItem('iid');
      switch (a) {
        case 1:
          data1.uupoint = "PC:首页";
          break;
        case 2:
          data1.uupoint = "PC:产品中心";
          break;
        case 3:
          data1.uupoint = "PC:综合解决方案";
          break;
        case 4:
          data1.uupoint = "PC:资讯中心";
          break;
        case 5:
          data1.uupoint = "PC:关于我们";
          break;
        case 6:
          data1.uupoint = "PC:管理中心";
          break;
        default:
          break;
      }
      data1.Webjson.enc = `{\"uid\":"",\"ip\":"${data1.iid}\",\"point\":"${data1.uupoint}\",\"type\":"${data1.type}\"}`
      // console.log(" 路由点击时的参数 ", data1.Webjson);
      // data1.websock.send(JSON.stringify(data1.Webjson));
      websocketonopen();

    };
    // 返还回去
    return {
      handleClick,
      scrollToTop,
      getIPAddress,
      initWebSocket,
      websocketonmessage,
      websocketsend,
      ...toRefs(data1),
    };
  },
});
