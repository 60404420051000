import http from "./https";
//
/**
 *  @parms resquest 请求地址 例如：http://197.82.15.15:8088/request/...
 *  @param '/testIp'代表vue-cil中config，index.js中配置的代理
 */

//* get请求
export function getListAPI(resquest, params) {
  return http.get(`${resquest}`, params);
}
//* post请求
export function postFormAPI(resquest, params) {
  // console.log("发送post请求");
  return http.post(`${resquest}`, params);
}
//* put 请求
export function putSomeAPI(resquest, params) {
  // return http.put(`${resquest}/putSome.json`, params);
  return http.put(`${resquest}`, params);
}
//* delete 请求
export function deleteListAPI(resquest, params) {
  // return http.delete(`${resquest}/deleteList.json`, params);
  return http.delete(`${resquest}`, params);
}
