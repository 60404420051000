let loandata = [
  {
    parts: "六安市叶集****业有限公司申请亿数贷产品已放款通过,放款金额30万",
    dq: "六安市",
    time: "刚刚",
  },
  {
    parts: "上海左****限公司正在申请众邦（上海）企业版产品",
    dq: "上海市",
    time: "2分钟前",
  },
  {
    parts: "山东蒙冀****有限公司正在申请望企贷PLUS产品",
    dq: "德州市",
    time: "5分钟前",
  },
  {
    parts: "广西联派****有限公司正在申请富税贷（爆款）产品",
    dq: "桂林市",
    time: "4分钟前",
  },
  {
    parts: "武汉泽华****有限公司申请富税贷（爆款）产品已审批通过,审批金额35万",
    dq: "武汉市",
    time: "8分钟前",
  },
  {
    parts:
      "河津市天****工有限公司申请富税贷（爆款）产品已审批通过,审批金额40万",
    dq: "河津市",
    time: "6分钟前",
  },
  {
    parts: "广州市番禺区****工程有限公司正在申请金城银行-金企贷产品",
    dq: "广州市",
    time: "刚刚",
  },
  {
    parts: "武汉泽华****有限公司申请富税贷（爆款）产品已审批通过,审批金额35万",
    dq: "武汉市",
    time: "10分钟前",
  },
  {
    parts: "杭州联****有限公司正在申请新网好企e贷产品",
    dq: "杭州市",
    time: "9分钟前",
  },
  {
    parts: "南宁市盛****招牌制作部正在申请金城银行-金企贷产品",
    dq: "南宁市",
    time: "10分钟前",
  },
  {
    parts: "龙南市****有限公司正在申请金城银行-金企贷产品",
    dq: "赣州市",
    time: "7分钟前",
  },
  {
    parts: "绍兴傲****限公司申请亿数贷产品已放款通过,放款金额30万",
    dq: "绍兴市",
    time: "刚刚",
  },
  {
    parts: "李沧区****金经营部正在申请富税贷（爆款）产品",
    dq: "青岛市",
    time: "3分钟前",
  },
  {
    parts: "永康市****传播有限公司正在申请容易贷产品,放款金额50万",
    dq: "永康市",
    time: "6分钟前",
  },
  {
    parts: "南昌市****有限公司放款富税贷（爆款）产品",
    dq: "南昌市",
    time: "13分钟前",
  },

  {
    parts: "盛世嘉华****有限公司正在申请好企e贷（爆款）产品",
    dq: "烟台市",
    time: "8分钟前",
  },
  {
    parts: "集安市***人参制品有限公司正在申请保企贷（新款）产品",
    dq: "集安市",
    time: "16分钟前",
  },
  {
    parts: "江苏****有限公司正在申请好企e贷产品",
    dq: "江苏省",
    time: "7分钟前",
  },

  {
    parts: "徐州****建设有限公司正在申请浦发发票贷产品",
    dq: "徐州市",
    time: "4分钟前",
  },
  {
    parts: "绍兴市上虞区永和镇****正在申请容易贷（爆款）产品",
    dq: "绍兴市",
    time: "1分钟前",
  },
  // 新增数据显示
  {
    parts: "江苏****科技有限公司正在申请大数金融（新款）产品",
    dq: "苏州市",
    time: "20分钟前",
  },
  {
    parts: "杭州****科技有限公司正在申请大树普惠产品",
    dq: "苏州市",
    time: "6分钟前",
  },

  {
    parts: "山东*****有限责任公司正在申请雨商贷",
    dq: "山东省",
    time: "7分钟前",
  },

  {
    parts: "上海****派遣有限公司正在申请金企贷产品",
    dq: "上海市",
    time: "11分钟前",
  },

  {
    parts: "绍兴市****有限公司正在申请亿数贷产品",
    dq: "绍兴市",
    time: "2分钟前",
  },
  {
    parts: "重庆****工程有限公司正在申请微商贷产品",
    dq: "重庆市",
    time: "16分钟前",
  },
  {
    parts: "东阳市****有限公司申请众商贷产品",
    dq: "东阳市",
    time: "10分钟前",
  },
  {
    parts: "云南****器材厂正在申请好企e贷",
    dq: "云南昆明",
    time: "11分钟前",
  },
  {
    parts: "宁波****物流有限公司正在申请京东金融产品",
    dq: "宁波市",
    time: "1分钟前",
  },
  {
    parts: "辽宁****科技有限公司正在申请浦发发票贷产品",
    dq: "辽宁",
    time: "8分钟前",
  },
  {
    parts: "长岛****珍品有限公司长岛办事处正在申请瀚华发票贷",
    dq: "长岛",
    time: "6分钟前",
  },
  // 新的数据
  {
    parts: "杭州****科技有限公司正在申请众商贷",
    dq: "杭州市",
    time: "3分钟前",
  },

  {
    parts: "衡水****有限公司正在申请惠业贷",
    dq: "衡水市",
    time: "刚刚",
  },
  {
    parts: "常州市****科技有限公司正在申请微商贷",
    dq: "常州市",
    time: "17分钟前",
  },

  {
    parts: "衡水****有限公司正在申请惠业贷",
    dq: "衡水市",
    time: "3分钟前",
  },

  {
    parts: "诸暨市****有限公司正在申请厚沃宝",
    dq: "诸暨市",
    time: "11分钟前",
  },

  {
    parts: "江苏***科技有限公司正在申请容易租",
    dq: "苏州市",
    time: "5分钟前",
  },

  {
    parts: "江苏****酒店有限公司正在申请微商贷",
    dq: "苏州市",
    time: "3分钟前",
  },

  {
    parts: "丹阳市****家居有限公司正在申请新网好商贷",
    dq: "丹阳市",
    time: "2分钟前",
  },

  {
    parts: "仪征******工程有限公司正在申请大树普惠",
    dq: "讷河市",
    time: "6分钟前",
  },

  {
    parts: "广西****装饰广告设计工程有限公司正在申请富登贷",
    dq: "广西",
    time: "9分钟前",
  },

  {
    parts: "常州市****有限公司正在申请极畅贷",
    dq: "常州市",
    time: "12分钟前",
  },

]

module.exports = {
  loandata: loandata
}

